import React from 'react';
import { DownCaretIcon } from '../../icons';

const MoreTableSelect = ({ options, onSelect }) => {
    const handleSelect = (event) => {
        onSelect(event.target.id);
    };

    return (
        <div className={'dropdown dropDownLight'}>
            <button
                type="button"
                className="btn-outline border-0 shadow-sm dropDownButton rounded columnSelectMore "
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                <div className="d-flex w-100 align-items-center dropDownLabel">
                    <span>More</span>
                    <DownCaretIcon />
                </div>
            </button>
            <div
                className="dropdown-menu dropDownSelectMenu"
                aria-labelledby="dropdownMenuLink"
            >
                {options.map((option) => (
                    <div
                        className="dropdown-item"
                        id={option}
                        onClick={handleSelect}
                        key={`moretable-${option}`}
                    >
                        {option}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default MoreTableSelect;
