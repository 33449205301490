import React, { useMemo, useState } from 'react';
import DropDownSelect from '../dropDownSelect';
import SearchFormValueInput from './searchFormValueInput';
import {
    convertKeyToLabel,
    convertLabelToKey,
} from '../../utilities/stringHelpers';
import { colors } from '../../constants';
import { RemoveIcon } from '../../icons';

// to do: move to helper?
function getDefaultOperator(searchOptions, table, field) {
    const type =
    searchOptions && table && field ? searchOptions[table][field].type : 'text';
    return type === 'enum' ? 'in' : '=';
}

const SearchFormField = ({
    searchOptions,
    onFieldChange,
    onRemove,
    values = null,
}) => {
    const [selectedTable, setSelectedTable] = useState(
        values ? values.fieldName.split('.')[0] : ''
    );
    const [selectedField, setSelectedField] = useState(
        values ? values.fieldName.split('.')[1] : ''
    );

    const tableChoices = useMemo(
        () =>
            searchOptions
                ? Object.keys(searchOptions).map((t) => convertKeyToLabel(t))
                : [],
        [searchOptions]
    );

    const fields = useMemo(
        () =>
            searchOptions && selectedTable
                ? [
                    ...Object.keys(searchOptions[selectedTable]).map((f) =>
                        convertKeyToLabel(f)
                    ),
                ]
                : [],
        [selectedTable]
    );

    const handleTableChange = (selection) => {
        setSelectedTable(convertLabelToKey(selection));
        setSelectedField('');
    };

    const handleFieldSelect = (v) => setSelectedField(convertLabelToKey(v));

    const workingValue = values || {
        fieldName: selectedField,
        operator: getDefaultOperator(searchOptions, selectedTable, selectedField),
        value: '',
    };

    const selectedTableLabel = selectedTable
        ? convertKeyToLabel(selectedTable)
        : null;

    const handleRemove = () => {
        if (values) {
            onRemove(values.id);
        } else {
            setSelectedTable('');
            setSelectedField('');
        }
    };

    return (
        <div
            className="w-100 mb-1 pe-2 d-flex align-items-center rounded shadow-sm"
            style={{ background: 'white' }}
        >
            <div style={{ width: '16.66%' }}>
                <DropDownSelect
                    options={tableChoices}
                    selected={selectedTableLabel}
                    onSelect={handleTableChange}
                    label="Select Table"
                    disabled={!!values}
                    buttonStyling={{ height: '35px' }}
                />
            </div>
            <div style={{ width: '16.66%' }}>
                {selectedTable && (
                    <DropDownSelect
                        options={fields}
                        selected={convertKeyToLabel(selectedField)}
                        onSelect={handleFieldSelect}
                        label="Select Field"
                        disabled={!!values}
                        buttonStyling={{ height: '35px' }}
                    />
                )}
            </div>
            <div
                className="d-flex gap-1 align-items-center"
                style={{ width: '66.66%' }}
            >
                {selectedField && (
                    <SearchFormValueInput
                        definition={{
                            category: selectedTable,
                            name: selectedField,
                            ...searchOptions[selectedTable][selectedField],
                        }}
                        value={workingValue}
                        onFieldChange={onFieldChange}
                    />
                )}
                {!!selectedTable && (
                    <button
                        className="btn ms-auto p-0 d-flex align-items-center"
                        style={{ background: colors.goldwood }}
                        onClick={handleRemove}
                    >
                        <RemoveIcon color="white" size="16px" />
                    </button>
                )}
            </div>
        </div>
    );
};

export default SearchFormField;
