import React, { useMemo } from 'react';
import { useAppSelector } from '../../store/hooks';
import { selectProductZones } from '../../store/slices/mapSlice';

const ProductZoning = ({ property, propertyZoning, onChange }) => {
    const productZoning = useAppSelector(selectProductZones);
    const { City } = property;
    const unincorporated = propertyZoning.is_unincorporated || false;

    const handleCheck = (e) => {
        onChange({ product_zone_id: null, is_unincorporated: e.target.checked });
    };

    const handleSelect = (e) => {
        onChange({
            product_zone_id: e.target.value === '' ? null : Number(e.target.value),
        });
    };

    const choices = useMemo(() => {
        return unincorporated
            ? productZoning.unincorporated[City.county] || []
            : productZoning.incorporated[City.name] || [];
    }, [propertyZoning.is_unincorporated, productZoning]);

    let productZoneId = propertyZoning.product_zone_id || '';

    // Note on select element: react will issue a warning on using
    // selected attribute. But this is used to ensure that user
    // knows to reselect zone id on incorporated change (react tracks value and
    // so its not reset when choices change)
    // Alternative may be to build 2 select elements and swap them out...
    return (
        <div className="d-flex gap-4 justify-content-between">
            <div className="form-check aro-checkbox">
                <input
                    className="form-check-input"
                    type="checkbox"
                    checked={unincorporated}
                    id="unincorporatedCheck"
                    onChange={handleCheck}
                />
                <label
                    className="form-check-label productZoning"
                    htmlFor="unincorporatedCheck"
                >
          Unincorporated
                </label>
            </div>
            {choices.length > 0 && (
                <select
                    name="zoneIds"
                    id="zoneIds"
                    onChange={handleSelect}
                    className="flex-grow-1 productZoning"
                    value={productZoneId}
                >
                    <option
                        value={''}
                        selected={productZoneId === null}
                        className="productZoningDropDownChoice"
                    >
            Unknown
                    </option>
                    {choices.map((choice) => (
                        <option
                            key={choice.name}
                            value={choice.id}
                            className="productZoningDropDownChoice"
                        >
                            {choice.name}
                        </option>
                    ))}
                </select>
            )}
        </div>
    );
};

export default ProductZoning;
