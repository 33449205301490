import React from 'react';

import SearchFormField from './searchFormField';
import { dataGridGenerateQLString } from '../../utilities/datagrid';

const FilterSelectCard = ({
    searchOptions,
    onFieldChange,
    onFieldRemove,
    filters,
}) => {
    const renderSearchValues = () => {
        const qlString = dataGridGenerateQLString(filters, searchOptions);
        return qlString.length ? (
            <span>Filters: {qlString}</span>
        ) : (
            <span>No Filters Set</span>
        );
    };

    return (
        <div className="pt-2">
            {filters.length ? (
                filters.map((f, i) => (
                    <React.Fragment key={f.id}>
                        <SearchFormField
                            searchOptions={searchOptions}
                            onFieldChange={onFieldChange}
                            onRemove={onFieldRemove}
                            values={f}
                        />
                        {i === filters.length - 1 && (
                            <SearchFormField
                                searchOptions={searchOptions}
                                onFieldChange={onFieldChange}
                                onRemove={onFieldRemove}
                            />
                        )}
                    </React.Fragment>
                ))
            ) : (
                <SearchFormField
                    searchOptions={searchOptions}
                    onFieldChange={onFieldChange}
                    onRemove={onFieldRemove}
                />
            )}
            <div className="mt-2" style={{ fontSize: '12px' }}>
                {renderSearchValues()}
            </div>
        </div>
    );
};

export default FilterSelectCard;
