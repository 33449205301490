import { aroApiSlice } from './apiSlice';
import { getBaseURL, getDataGridURL } from '../../../utilities/config';

export const dataGridApiSlice = aroApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getDGSearchOptions: builder.query({
            query: () => `${getBaseURL()}/query`,
            transformResponse: (response) => {
                const transformedResponse = { ...response };
                for (let table in transformedResponse) {
                    for (let field in transformedResponse[table]) {
                        if (transformedResponse[table][field].type === 'enum') {
                            const temp = transformedResponse[table][field].values.filter(
                                (v) => v !== null
                            );
                            transformedResponse[table][field].values = temp.toSorted();
                        }
                    }
                }
                return { ...transformedResponse };
            },
        }),
        getDGData: builder.query({
            query: ({ query }) =>
                `${getDataGridURL()}/v1?query=${encodeURI(JSON.stringify(query))}`,
        }),
    }),
});

export const { useLazyGetDGSearchOptionsQuery, useLazyGetDGDataQuery } =
  dataGridApiSlice;
