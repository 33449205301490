import React, { useEffect, useMemo, useState } from 'react';
import DropDownSearchable from '../dropDownSearchable';
import Loader from '../loader/loader';
import {
    useLazyGetProjectQuery,
    useUpdateProjectMutation,
} from '../../store/slices/apiSlice/project-api-slice';
import { toastWarning, toastSuccess } from '../../utilities/toast';
import { handleBackendError } from '../../utilities/errorHandling';
import { objectDeepClone } from '../../utilities/objectHelpers';
import {
    DevelopmentManagerCard,
    FoundationTypeCard,
    NotesCard,
    ProductVersionCard,
    RosterCard,
    WaterMeterCard,
} from './cards';
import ProjectSaveButton from './projectSaveButton';
import './scoutProject.css';

const ProjectCards = [
    { Component: ProductVersionCard, name: 'productVersionCard' },
    { Component: FoundationTypeCard, name: 'foundationTypeCard' },
    { Component: NotesCard, name: 'notesCard', styleClass: 'doubleRow' },
    { Component: DevelopmentManagerCard, name: 'developmentManagerCard' },
    { Component: WaterMeterCard, name: 'waterMeterCard' },
    { Component: RosterCard, name: 'rosterCard' },
];

const ScoutProject = ({ property }) => {
    const { Projects } = property;
    const [getProject, getProjectResults] = useLazyGetProjectQuery();
    const [updateProject, updateProjectResult] = useUpdateProjectMutation();
    const [selectedProject, setSelectedProject] = useState(Projects[0] || {});
    const [isLoading, setIsLoading] = useState(false);
    const [projectInfo, setProjectInfo] = useState();
    const [projectUpdates, setProjectUpdates] = useState({});

    const projectChoices = useMemo(
        () =>
            Projects.map((p) => ({
                value: p?.id,
                label: `${p?.aro_id} - ${p?.alt_name}`,
            })),
        [Projects]
    );

    useEffect(() => {
        if (selectedProject?.aro_id) {
            setProjectInfo();
            setProjectUpdates({});
            setIsLoading(true);
            getProject(selectedProject.id);
        }
    }, [selectedProject]);

    useEffect(() => {
        const { isError, status, isSuccess, data } = getProjectResults;
        let timer = null;
        if (isError) {
            timer = setTimeout(() => {
                toastWarning('Things are a little slow right now. Trying again...');
                getProject(selectedProject.id);
                timer = null;
            }, 5000);
        } else if (status === 'fulfilled' && isSuccess) {
            setProjectInfo(data);
            setIsLoading(false);
        }
        return () => {
            if (timer !== null) {
                clearTimeout(timer);
            }
        };
    }, [getProjectResults]);

    useEffect(() => {
        const { isError, status, isSuccess, data } = updateProjectResult;
        if (isError) {
            handleBackendError('Update Project', updateProjectResult);
        } else if (status === 'fulfilled' && isSuccess) {
            if (data?.result === 'Success') {
                toastSuccess('Project Updated');
                setProjectUpdates({});
                getProject(selectedProject.id);
            } else {
                handleBackendError('Update Project', updateProjectResult);
            }
        }
    }, [updateProjectResult]);

    const handleProjectChange = (selected) => {
        const aroId = selected.value;
        const proj = Projects.find((p) => p.id === aroId);
        if (proj) {
            setSelectedProject(proj);
            setProjectInfo(null);
        }
    };

    const handleUpdate = (updates) => {
        const updatedProjectInfo = objectDeepClone(projectInfo);
        const newUpdates = objectDeepClone(projectUpdates);
        updates.forEach(({ sectionName, fieldName, value }) => {
            const updatedValue = value == 0 ? null : value;
            updatedProjectInfo[sectionName][fieldName] = updatedValue;
            if (!newUpdates[sectionName]) {
                newUpdates[sectionName] = {};
            }
            newUpdates[sectionName][fieldName] = updatedValue;
        });
        setProjectInfo(updatedProjectInfo);
        setProjectUpdates(newUpdates);
    };

    const handleSave = () => {
        updateProject({
            projectId: selectedProject.id,
            projectUpdates,
        });
    };

    const isSaveDisabled = Object.keys(projectUpdates).length < 1;

    const projectIndex = projectChoices.findIndex(
        (opt) => opt.value === selectedProject.id
    );

    return (
        <div className="scoutProject">
            <div className="mt-1 d-flex justify-content-between">
                <div className="projectSelectContainer">
                    <DropDownSearchable
                        options={projectChoices}
                        value={projectChoices[projectIndex]}
                        onSelect={handleProjectChange}
                        disabled={projectChoices.length <= 1}
                        selectedOptionLabelStyles={{
                            fontSize: '18px',
                            fontWeight: 'normal',
                        }}
                    />
                </div>
                <ProjectSaveButton disabled={isSaveDisabled} onSave={handleSave} />
            </div>

            {isLoading ? (
                <Loader />
            ) : (
                !!projectInfo && (
                    <div className="mt-2 cardsSection">
                        {ProjectCards.map((card) => {
                            const { name, Component, styleClass = '' } = card;
                            return (
                                <div
                                    key={name}
                                    className={`projectCardContainer ${styleClass}`}
                                >
                                    <Component project={projectInfo} onUpdate={handleUpdate} />
                                </div>
                            );
                        })}
                    </div>
                )
            )}
        </div>
    );
};

export default ScoutProject;
