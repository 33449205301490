import React, { useMemo } from 'react';
import DropDownSearchable from '../../dropDownSearchable';

const WaterMeterCard = ({ project, onUpdate }) => {
    const {
        ProjectDetails,
        init: { water_meter_size },
    } = project;
    const reuseExisting = ProjectDetails?.reuse_existing_water_meter || false;
    const existingMeterId = ProjectDetails?.existing_water_meter_id || null;
    const newMeterId = ProjectDetails?.new_water_meter_id || null;

    const meterSizeOptions = useMemo(
        () =>
            Object.keys(water_meter_size).map((key) => ({
                label: water_meter_size[key].name,
                value: Number(key),
            })),
        [water_meter_size]
    );

    const handleCheck = () => {
        const newValue = !reuseExisting;
        const updates = [
            {
                sectionName: 'ProjectDetails',
                fieldName: 'reuse_existing_water_meter',
                value: newValue,
            },
        ];
        if (newValue) {
            updates.push({
                sectionName: 'ProjectDetails',
                fieldName: 'new_water_meter_id',
                value: existingMeterId,
            });
        }
        onUpdate(updates);
    };

    const handleSelectNew = (selected) => {
        onUpdate([
            {
                sectionName: 'ProjectDetails',
                fieldName: 'new_water_meter_id',
                value: Number(selected.value),
            },
        ]);
    };

    const handleSelectExisting = (selected) => {
        const updates = [
            {
                sectionName: 'ProjectDetails',
                fieldName: 'existing_water_meter_id',
                value: Number(selected.value),
            },
        ];
        if (reuseExisting) {
            updates.push({
                sectionName: 'ProjectDetails',
                fieldName: 'new_water_meter_id',
                value: Number(selected.value),
            });
        }
        onUpdate(updates);
    };

    const getSelected = (id) => meterSizeOptions.find((opt) => opt.value === id);

    return (
        <>
            <div className="d-flex justify-content-between">
                <h3 className="cardTitle">Water Meter</h3>
                <div className="form-check aro-checkbox">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        checked={reuseExisting}
                        id="reuseExistingCheck"
                        onChange={handleCheck}
                        disabled={existingMeterId === null}
                    />
                    <label className="form-check-label" htmlFor="reuseExistingCheck">
            Reuse Existing
                    </label>
                </div>
            </div>

            <div className="mt-1 d-flex flex-column gap-2 w-100">
                <div className="row align-items-center">
                    <h4 className="col-4 fieldLabel">Existing</h4>
                    <div className="col-8">
                        <DropDownSearchable
                            options={meterSizeOptions}
                            value={getSelected(existingMeterId)}
                            onSelect={handleSelectExisting}
                            sortOptions={false}
                            compact={true}
                        />
                    </div>
                </div>
                <div className="row align-items-center">
                    <h4 className="col-4 fieldLabel">New</h4>
                    <div className="col-8">
                        <DropDownSearchable
                            options={meterSizeOptions}
                            value={getSelected(newMeterId)}
                            onSelect={handleSelectNew}
                            disabled={reuseExisting}
                            sortOptions={false}
                            compact={true}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default WaterMeterCard;
