const AllCaps = ['csi', 'mls', 'hoa', 'avm', 'coa', 'fsd', 'apn', 'asce', 'ss'];

export function convertKeyToLabel(key) {
    return key
        .toLowerCase()
        .split('_')
        .map((word) =>
            AllCaps.includes(word)
                ? word.toUpperCase()
                : word.charAt(0).toUpperCase() + word.slice(1)
        )
        .join(' ');
}

export const convertLabelToKey = (label) => {
    return label.toLowerCase().replaceAll(' ', '_');
};
