import React, { useMemo } from 'react';
import DropDownSearchable from '../../dropDownSearchable';

const ProductVersionCard = ({ project, onUpdate }) => {
    const {
        Project,
        init: { home_products, nta_version },
    } = project;
    const selectedProduct = Project?.home_product_id || null;
    const selectedNTAVersion = Project?.nta_version_id || null;

    const productOptions = useMemo(
        () =>
            Object.keys(home_products).map((key) => ({
                label: `${home_products[key].name} (${home_products[key].aro_id})`,
                value: home_products[key].id,
            })),
        [home_products]
    );

    const ntaVersionOptions = useMemo(() => {
        const opts = [];
        Object.keys(nta_version).forEach((key) => {
            if (nta_version[key].home_product_id === selectedProduct) {
                opts.push({
                    label: nta_version[key].name,
                    value: nta_version[key].id,
                });
            }
        });
        opts.sort((a, b) =>
            a.label.localeCompare(b.label, undefined, { numeric: true })
        );
        return opts;
    }, [nta_version, selectedProduct]);

    const findSelected = (val, opts) => opts.find((opt) => opt.value === val);

    const handleSelectProduct = (selected) => {
        onUpdate([
            {
                sectionName: 'Project',
                fieldName: 'home_product_id',
                value: Number(selected.value),
            },
            {
                sectionName: 'Project',
                fieldName: 'nta_version_id',
                value: null,
            },
        ]);
    };

    const handleSelectNTAVersion = (selected) => {
        onUpdate([
            {
                sectionName: 'Project',
                fieldName: 'nta_version_id',
                value: Number(selected.value),
            },
        ]);
    };

    return (
        <div>
            <div>
                <h3 className="col-5 cardTitle">Product Version</h3>
                <div className="row align-items-center">
                    <label className="col-4 fieldLabel">Product:</label>
                    <div className="col-8">
                        <DropDownSearchable
                            options={productOptions}
                            value={findSelected(selectedProduct, productOptions)}
                            onSelect={handleSelectProduct}
                            compact={true}
                        />
                    </div>
                </div>
                <div className="row mt-1 align-items-center ntaVersionContainer">
                    {!!selectedProduct && (
                        <>
                            <label className="col-4 fieldLabel">NTA Version:</label>
                            <div className="col-8">
                                <DropDownSearchable
                                    options={ntaVersionOptions}
                                    value={findSelected(selectedNTAVersion, ntaVersionOptions)}
                                    onSelect={handleSelectNTAVersion}
                                    sortOptions={false}
                                    disabled={selectedProduct === null}
                                    compact={true}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ProductVersionCard;
