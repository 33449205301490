import React, { useMemo } from 'react';
import DropDownSearchable from '../../dropDownSearchable';

const Roster = [
    { key: 'civil_engineer_id', label: 'Civil Engineer' },
    { key: 'geotechnical_engineer_id', label: 'Geotechnical Engineer' },
    { key: 'surveyor_id', label: 'Surveyor' },
    { key: 'landscape_architect_id', label: 'Landscape Architect' },
    { key: 'arborist_id', label: 'Arborist' },
    { key: 'structural_engineer_id', label: 'Structural Engineer' },
    { key: 'architect_id', label: 'Architect' },
    { key: 'fire_protection_id', label: 'Fire Protection' },
];

const RosterCard = ({ project, onUpdate }) => {
    const {
        init: { vendor },
        ProjectDetails,
    } = project;

    const vendorOptions = useMemo(() => {
        const vendors = [];
        for (let key in vendor) {
            if (vendor[key].company_name && vendor[key].entity_name) {
                vendors.push({
                    label: vendor[key].company_name,
                    value: Number(key),
                });
            }
        }
        vendors.sort((a, b) => (a.label > b.label ? 1 : -1));
        return [{ label: 'not assigned', value: null }, ...vendors];
    }, [vendor]);

    const handleSelect = (role, s) => {
        onUpdate([
            {
                sectionName: 'ProjectDetails',
                fieldName: role.key,
                value: s.value,
            },
        ]);
    };

    const getSelectedOption = (roleKey) => {
        const val = ProjectDetails[roleKey] || null;
        if (!val) {
            return null;
        }
        return vendorOptions.find((opt) => opt.value === val);
    };

    return (
        <div>
            <h3 className="cardTitle">Roster</h3>
            {Roster.map((role) => (
                <div
                    key={role.key}
                    className="mt-2 d-flex justify-content-between align-items-center"
                >
                    <label className="fieldLabel">{role.label}</label>
                    <div className="rosterSelectContainer">
                        <DropDownSearchable
                            options={vendorOptions}
                            sortOptions={false}
                            value={getSelectedOption(role.key)}
                            onSelect={(s) => handleSelect(role, s)}
                            compact={true}
                        />
                    </div>
                </div>
            ))}
        </div>
    );
};

export default RosterCard;
