import { nanoid } from 'nanoid';

// input: object with keys corresponding to tables and the values being list with field names and boolean values
// output: array of fieldnames(that include table) that are selected
// Ex. input { abc: { fld1: false, fld2: true}, def: {fld2: true}}
//     output [ abc.fld2, def.fld2]
export function dataGridGetSelectedColumnNames(columns) {
    const selectedColumns = [];
    for (let table in columns) {
        for (let field in columns[table]) {
            if (columns[table][field]) {
                selectedColumns.push(`${table}.${field}`);
            }
        }
    }
    return selectedColumns;
}

// reverse of getSelectedColumns - returns object...
export function dataGridGetColumnsFromSelections(selections) {
    const columns = {};
    for (let col of selections) {
        let words = col.split('.');
        let tbl = words[0];
        let fld = words[1];
        if (!columns[tbl]) {
            columns[tbl] = {};
        }
        columns[tbl][fld] = true;
    }
    return columns;
}

export function dataGridGenerateQLString(searchValues, searchOptions) {
    const values = [];
    let v;
    let units;
    let nUnits;

    for (let f of searchValues) {
        if (isNullCheckOperator(f.operator)) {
            values.push(
                `${f.fieldName} ${f.operator === 'empty' ? 'IS NULL' : 'IS NOT NULL'}`
            );
        } else if (isRelativeDateOperator(f.operator)) {
            units = f.value?.units || null;
            nUnits = f.value?.nUnits || null;
            if (units && nUnits != null) {
                values.push(
                    `${f.fieldName} ${f.operator.slice(0, 2)} ${nUnits}${units}`
                );
            }
        } else if (f.value) {
            const [table, field] = f.fieldName.split('.');
            const type = table && field ? searchOptions[table][field].type : 'text';
            if (['currency', 'number'].includes(type)) {
                values.push(`${f.fieldName}${f.operator}${f.value}`);
            } else if (f.operator === 'contains') {
                v = f.value.replace(/^%+|%+$/g, '');
                values.push(`${f.fieldName} ILIKE '%${v}%'`);
            } else if (isInOperator(f.operator)) {
                if (f.value.length) {
                    let v = `(${f.value.map((s) => `'${s}'`).join(',')})`;
                    values.push(`${f.fieldName} ${f.operator.toUpperCase()} ${v}`);
                }
            } else {
                values.push(`${f.fieldName}${f.operator}'${f.value}'`);
            }
        }
    }
    return values.join(' AND ');
}

// Browser URL helpers
// - URL query string is generated same as the API query string. These helpers
// are for re-encoding the string.

export function dataGridGetOptionFromSearchParams(searchParams, option) {
    const query = searchParams.get('query');
    if (!query) {
        return null;
    }
    try {
        const parsedOptions = JSON.parse(decodeURI(query));
        return parsedOptions[option] || null;
    } catch (e) {
        console.log('error decoding search params', { searchParams, option });
        return null;
    }
}

const qlToOpTranslations = {
    ILIKE: 'contains',
    'IS NULL': 'empty',
    'IS NOT NULL': 'not empty',
    IN: 'in',
    'NOT IN': 'not in',
};

const translateQLtoOp = (rawOp) => qlToOpTranslations[rawOp] || rawOp;
// filter format is table.field op optionalValue
// where op is <, <=, ...
// and optionalValue is optional. If present and singled quoted string, single quotes are removed
const filterRegex =
  /^(\w+)\.(\w+)\s*(<=|>=|!=|=|<|>|ILIKE|IN|NOT IN|IS NULL|IS NOT NULL)\s*('?(.*?)'?)?$/i;

// input: single table/field filter string from browser URL QL filters string
// output: object with filter table, field, operator and value
export function dataGridParseFilterString(filter) {
    const match = filter.match(filterRegex);
    if (!match) {
        console.log('Invalid query format', filter);
        return null;
    }

    const [, /*filter string*/ table, field, rawOp /*value*/, , rawValue] = match;
    // remove % (for contains/ILIKE) and change list to arrray(for IN, NOT IN)
    let op = translateQLtoOp(rawOp.trim());
    let value = rawValue ? rawValue.replace(/^%|%$/g, '') : '';
    if (isInOperator(op)) {
        value = value.match(/'([^']*)'/g).map((s) => s.replace(/^'|'$/g, ''));
    }
    return { table, field, op, value };
}

// input: filters value from browser query string
// output: array of filter values
export function dataGridBuildFiltersFromQueryString(
    queryStringFilters,
    searchOptions
) {
    const filters = [];
    if (queryStringFilters) {
        const filterStrings = queryStringFilters.split(' AND ');
        for (let filter of filterStrings) {
            const filterDefinition = dataGridParseFilterString(filter);
            if (filterDefinition) {
                const { table, field, op, value } = filterDefinition;
                let filterValue = value;
                let opString = op.trim();
                if (isInOperator(opString)) {
                    filterValue = filterValue.map((s) => s.replace(/^'|'$/g, ''));
                } else if (
                    ['>=', '<='].includes(opString) &&
          searchOptions[table][field]?.type === 'date'
                ) {
                    const formatInfo = detectDateValueFormat(value);
                    if (!formatInfo) {
                        return [];
                    } else if (formatInfo.format === 'number-unit') {
                        filterValue = {
                            units: formatInfo.value[2],
                            nUnits: formatInfo.value[1],
                        };
                        opString = opString + ' relative';
                    }
                }
                filters.push({
                    id: nanoid(),
                    fieldName: `${table}.${field}`,
                    operator: opString,
                    value: filterValue,
                });
            } else {
                return [];
            }
        }
    }
    return filters;
}

function detectDateValueFormat(str) {
    // Regex for date format (M/D/YYYY or MM/DD/YYYY)
    const dateRegex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;

    // Regex for "<number><unit>" format, where number can be negative and unit is 'w' or 'd'
    const testNumUnitRegex = /^-?\d+[wd]$/;
    const numUnitRegex = /^(-?\d+)([wd])$/;

    if (dateRegex.test(str)) {
        return { format: 'date', value: str.match(dateRegex) };
    } else if (testNumUnitRegex.test(str)) {
        return {
            format: 'number-unit',
            value: str.match(numUnitRegex),
        };
    } else {
        return { format: 'unknown', value: null };
    }
}

export const isNullCheckOperator = (op) => ['empty', 'not empty'].includes(op);

export const isRelativeDateOperator = (op) =>
    ['>= relative', '<= relative'].includes(op);

export const isInOperator = (op) =>
    ['in', 'not in', 'IN', 'NOT IN'].includes(op);
