import React from 'react';
import './selector.css';
import { DownCaretIcon } from '../../icons';

export const DropDownColorTheme = { light: 'light', dark: 'dark' };

const DropDownSelect = ({
    options,
    selected,
    onSelect,
    label = null,
    colorTheme = DropDownColorTheme.light,
    disabled = false,
    buttonStyling = null,
    buttonLabelStyling = null,
}) => {
    const colorThemeClass =
    colorTheme === DropDownColorTheme.light ? 'dropDownLight' : 'dropDownDark';
    const handleSelect = (event) => {
        onSelect(event.target.id);
    };

    return (
        <div className={`dropdown ${colorThemeClass}`}>
            <button
                type="button"
                className="btn-outline border-0 shadow-sm dropDownButton rounded-2 py-1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                disabled={disabled}
                style={buttonStyling}
            >
                <div
                    className="d-flex w-100 align-items-center dropDownLabel"
                    style={buttonLabelStyling}
                >
                    {selected || label}
                    <DownCaretIcon />
                </div>
            </button>
            <div
                className="dropdown-menu dropDownSelectMenu"
                aria-labelledby="dropdownMenuLink"
            >
                {label && (
                    <div
                        className="dropdown-item"
                        style={{
                            display: selected == label ? 'none' : 'block',
                        }}
                        id={''}
                        onClick={handleSelect}
                    >
                        {label}
                    </div>
                )}
                {options
                    .sort((a, b) => (a > b ? 1 : -1))
                    .map((option, index) => (
                        <div
                            className="dropdown-item"
                            style={{
                                display: selected == option ? 'none' : 'block',
                            }}
                            id={option}
                            onClick={handleSelect}
                            key={`${label}-${index}`}
                        >
                            {option}
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default DropDownSelect;
