import React, { useEffect, useState } from 'react';
import {
    convertKeyToLabel,
    convertLabelToKey,
} from '../../utilities/stringHelpers';
import MoreTableSelect from './moreTableSelect';
import TableFieldsSelect from './tableFieldsSelect';
import './datagridColumnSelectCard.css';

const DEFAULT_TABLES = ['project', 'task', 'expense', 'property', 'csi_code'];

const ColumnSelectCard = ({ searchOptions, selectedColumns, onSelect }) => {
    const [visibleTables, setVisibleTables] = useState([]);
    const [moreTables, setMoreTables] = useState([]);
    useEffect(() => {
        if (Object.keys(searchOptions).length) {
            setVisibleTables(
                Object.keys(searchOptions).filter((opt) => DEFAULT_TABLES.includes(opt))
            );
            setMoreTables(
                Object.keys(searchOptions)
                    .filter((opt) => !DEFAULT_TABLES.includes(opt))
                    .map((t) => convertKeyToLabel(t))
                    .sort()
            );
        }
    }, [searchOptions]);

    const handleMoreTablesSelect = (selected) => {
        if (selected) {
            setVisibleTables([...visibleTables, convertLabelToKey(selected)]);
            const updatedMore = [...moreTables].filter((c) => c != selected).sort();
            setMoreTables(updatedMore);
        }
    };

    const handleHide = (category) => {
        setVisibleTables((cols) => cols.filter((c) => c != category));
        setMoreTables([...moreTables, convertKeyToLabel(category)].sort());
    };

    return (
        <div>
            <div className="d-flex flex-wrap gap-2">
                {visibleTables.map((category) => {
                    const fieldSelections = selectedColumns.filter((c) => {
                        const [table] = c.split('.');
                        return table === category;
                    });
                    return (
                        <TableFieldsSelect
                            key={`columnselect-${category}`}
                            title={category}
                            fieldOptions={searchOptions[category]}
                            selectedFields={fieldSelections}
                            onCheck={onSelect}
                            onHide={handleHide}
                        />
                    );
                })}
                <MoreTableSelect
                    options={moreTables}
                    onSelect={handleMoreTablesSelect}
                />
            </div>

            <div className="mt-2 selectedSummary">
                {selectedColumns.length ? (
                    <>Selected: {selectedColumns.join(', ')}</>
                ) : (
                    'No Selections'
                )}
            </div>
        </div>
    );
};

export default ColumnSelectCard;
