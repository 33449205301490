import React from 'react';
import CurrencyInput from 'react-currency-input-field';
import DropDownSelect from '../dropDownSelect';
import DropDownSearchable from '../dropDownSearchable';

import {
    isNullCheckOperator,
    isRelativeDateOperator,
} from '../../utilities/datagrid';
import RelativeDateInput from './relativeDateInput';

const OperatorDropDown = ({ selected, options, onChange }) => (
    <div style={{ minWidth: '130px' }}>
        <DropDownSelect
            options={options}
            selected={selected}
            onSelect={onChange}
            buttonStyling={{
                height: '35px',
            }}
            buttonLabelStyling={{
                alignItems: 'center',
                justifyContent: 'space-around',
            }}
        />
    </div>
);

const NumberOperatorOptions = [
    '<',
    '<=',
    '>',
    '>=',
    '=',
    '!=',
    'empty',
    'not empty',
];
const DateOperatorOptions = [
    ...NumberOperatorOptions,
    '<= relative',
    '>= relative',
];

const SearchFormValueInput = ({ definition, value, onFieldChange }) => {
    const handleInputChange = (e) => {
        onFieldChange(definition, value?.id, value.operator, e.target.value);
    };
    const handleOperatorChange = (v) => {
        const fieldValue = isNullCheckOperator(v) ? '' : value?.value;
        onFieldChange(definition, value?.id, v, fieldValue);
    };

    const handleValueChange = (v) => {
        onFieldChange(definition, value?.id, value.operator, v);
    };

    const handleMultiSelectChange = (selections) => {
        const selected = selections.map((s) => s.value);
        onFieldChange(definition, value?.id, value.operator, selected);
    };

    const handleRelativeDateChange = (units, nUnits) => {
        onFieldChange(definition, value?.id, value.operator, {
            units,
            nUnits,
        });
    };

    const getInputField = () => {
        let options;
        let defaultValue;
        switch (definition.type) {
        case 'date':
            return (
                <>
                    <OperatorDropDown
                        options={DateOperatorOptions}
                        selected={value.operator}
                        onChange={handleOperatorChange}
                    />
                    {isRelativeDateOperator(value.operator) ? (
                        <RelativeDateInput
                            value={value.value}
                            onChange={handleRelativeDateChange}
                        />
                    ) : (
                        !isNullCheckOperator(value.operator) && (
                            <input
                                type="date"
                                autoFocus
                                value={value.value.toString()}
                                onChange={handleInputChange}
                                style={{ width: '200px' }}
                            />
                        )
                    )}
                </>
            );
        case 'number':
            return (
                <>
                    <OperatorDropDown
                        options={NumberOperatorOptions}
                        selected={value.operator}
                        onChange={handleOperatorChange}
                    />
                    {!isNullCheckOperator(value.operator) && (
                        <input
                            type="number"
                            autoFocus
                            value={value.value.toString()}
                            onChange={handleInputChange}
                            style={{
                                marginRight: 0,
                                marginInlineEndWidth: '100%',
                                textAlign: 'right',
                            }}
                        />
                    )}
                </>
            );
        case 'boolean':
            return (
                <>
                    <OperatorDropDown
                        options={['=', 'empty', 'not empty']}
                        selected={value.operator}
                        onChange={handleOperatorChange}
                    />
                    {!isNullCheckOperator(value.operator) && (
                        <DropDownSelect
                            options={['true', 'false']}
                            selected={value.value.toString()}
                            onSelect={handleValueChange}
                            label="Select"
                        />
                    )}
                </>
            );
        case 'enum':
            options = definition.values.map((v) => ({ value: v, label: v }));
            defaultValue = value.value
                ? value.value.map((v) => ({ value: v, label: v }))
                : null;
            return (
                <>
                    <OperatorDropDown
                        options={['in', 'not in', 'empty', 'not empty']}
                        selected={value.operator}
                        onChange={handleOperatorChange}
                    />
                    {!isNullCheckOperator(value.operator) && (
                        <DropDownSearchable
                            options={options}
                            isMulti={true}
                            onSelect={handleMultiSelectChange}
                            value={defaultValue}
                        />
                    )}
                </>
            );
        case 'currency':
            return (
                <>
                    <OperatorDropDown
                        options={NumberOperatorOptions}
                        selected={value.operator}
                        onChange={handleOperatorChange}
                    />
                    {!isNullCheckOperator(value.operator) && (
                        <CurrencyInput
                            size="12"
                            value={value.value}
                            id={`${definition.category}-${definition.name}`}
                            name={`${definition.category}-${definition.name}`}
                            allowDecimals={true}
                            allowNegativeValue={false}
                            onValueChange={handleValueChange}
                            prefix="$"
                            autoFocus
                            style={{
                                borderColor: '#d9d9d9',
                                height: '26px',
                                textAlign: 'right',
                            }}
                        />
                    )}
                </>
            );
        case 'string':
        default:
            return (
                <>
                    <OperatorDropDown
                        options={['=', 'contains', 'empty', 'not empty']}
                        selected={value.operator}
                        onChange={handleOperatorChange}
                    />
                    {!isNullCheckOperator(value.operator) && (
                        <input
                            value={value.value.toString()}
                            onChange={handleInputChange}
                            autoFocus
                            style={{
                                height: '26px',
                                marginRight: 0,
                                width: '100%',
                                textAlign: 'left',
                            }}
                        />
                    )}
                </>
            );
        }
    };
    return (
        <div className="d-flex gap-1 align-items-center" style={{ flex: '1' }}>
            {getInputField()}
        </div>
    );
};

export default SearchFormValueInput;
