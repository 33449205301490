import React, { useEffect, useState } from 'react';
import Page from '../page';
import Loader from '../loader/loader';
import { useAppSelector } from '../../store/hooks';
import { selectUserProfile } from '../../store/slices/authSlice';
import { useLazyGetDGSearchOptionsQuery } from '../../store/slices/apiSlice/data-grid-api-slice';
import { toastWarning } from '../../utilities/toast';
import { UserTypes } from '../../constants';
import DataGrid from './datagrid';

function DataGridPage() {
    const [getDGSearchOptions, getDGSearchOptionsResult] =
    useLazyGetDGSearchOptionsQuery();
    const userProfile = useAppSelector(selectUserProfile);

    const [searchOptions, setSearchOptions] = useState();

    useEffect(() => {
        if (userProfile === UserTypes.aro) {
            getDGSearchOptions();
        }
    }, [userProfile]);

    useEffect(() => {
        const {
            isError,
            status,
            isSuccess,
            data: searchOptionsData,
        } = getDGSearchOptionsResult;
        let timer = null;
        if (isError) {
            timer = setTimeout(() => {
                toastWarning('Things are a little slow right now. Trying again...');
                getDGSearchOptions();
                timer = null;
            }, 5000);
        } else if (status === 'fulfilled' && isSuccess) {
            setSearchOptions(searchOptionsData);
        }
        return () => {
            if (timer !== null) {
                clearTimeout(timer);
            }
        };
    }, [getDGSearchOptionsResult]);

    if (userProfile === UserTypes.external) {
        return (
            <Page>
                <div className="px-4 pt-4">This page is not available.</div>
            </Page>
        );
    }

    return (
        <Page>
            <div className="px-4 py-1">
                {!searchOptions ? (
                    <Loader />
                ) : (
                    <DataGrid searchOptions={searchOptions} />
                )}
            </div>
        </Page>
    );
}

export default DataGridPage;
