import React from 'react';
import Select from 'react-select';
import { colors } from '../../constants';

const customSearchFilter = (option, searchText) =>
    option.data.label.toLowerCase().includes(searchText.toLowerCase());

const CompactHeight = '30px';
// Options is array of objects (properties label, value). Label will be searched
const DropDownSearchable = ({
    options,
    value = null,
    isMulti = false,
    sortOptions = true,
    disabled = false,
    selectedOptionLabelStyles = {},
    compact = false,
    onSelect,
}) => {
    if (sortOptions) {
        options.sort((a, b) => (a.label > b.label ? 1 : -1));
    }

    let extraControlStyling = {};
    let extraValueContainerStyling = {};
    let extraIndicatorsContainerStyling = {};
    let extraInputStyling = {};
    if (compact) {
        extraControlStyling = {
            borderRadius: '6px',
            minHeight: CompactHeight,
            height: CompactHeight,
        };
        extraValueContainerStyling = {
            height: CompactHeight,
            padding: '0 6px',
        };
        extraIndicatorsContainerStyling = { height: CompactHeight };
        extraInputStyling = { marginTop: '-4px', paddingTop: 0, paddingZBottom: 0 };
    }

    return (
        <Select
            isMulti={isMulti}
            isSearchable={true}
            filterOption={customSearchFilter}
            value={value}
            isDisabled={disabled}
            options={options}
            onChange={onSelect}
            className={isMulti ? 'basic-multi-select' : null}
            styles={{
                option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected ? colors.skyfall : 'inherit',
                }),
                control: (provided, state) => ({
                    ...provided,
                    ...selectedOptionLabelStyles,
                    ...extraControlStyling,
                    backgroundColor: state.isDisabled ? 'white !important' : 'white',
                }),
                valueContainer: (provided) => ({
                    ...provided,
                    extraValueContainerStyling,
                }),
                singleValue: (provided, state) => ({
                    ...provided,
                    color: state.isDisabled ? colors.charcoal : colors.charcoal,
                }),
                input: (provided) => ({
                    ...provided,
                    ...extraInputStyling,
                }),
                indicatorSeparator: (provided, state) => ({
                    ...provided,
                    display: state.isDisabled ? 'none' : 'inherit',
                }),
                indicatorsContainer: (provided, state) => ({
                    ...provided,
                    ...extraIndicatorsContainerStyling,
                    display: state.isDisabled ? 'none' : 'inherit',
                }),
            }}
            theme={(theme) => ({
                ...theme,
                borderRadius: 10,
                colors: {
                    ...theme.colors,
                    primary: colors.skyfallDark,
                },
            })}
        />
    );
};

export default DropDownSearchable;
