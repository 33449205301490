import React, { useState } from 'react';
import { nanoid } from 'nanoid';
import { SearchIcon } from '../../icons';

import ColumnSelectCard from '../datagridColumnSelectCard';
import FilterSelectCard from './filterSelectCard';
import ClearAllButton from './clearAllButton';
import './datagridSearchForm.css';

function DataGridSearchForm({
    initialFilterValues,
    initialColumnSelections,
    searchOptions,
    onSearch,
}) {
    const [filters, setFilters] = useState([...initialFilterValues]);
    const [selectedColumns, setSelectedColumns] = useState([
        ...initialColumnSelections,
    ]);

    const handleFieldChange = (fieldDef, id, op, value) => {
        const updatedFilters = [...filters];
        const f = updatedFilters.find((filter) => filter.id === id);
        if (f) {
            f.value = value;
            f.operator = op;
        } else {
            updatedFilters.push({
                id: nanoid(),
                fieldName: `${fieldDef.category}.${fieldDef.name}`,
                operator: op,
                value: value,
            });
        }
        setFilters(updatedFilters);
    };

    const handleFieldRemove = (id) => {
        const index = filters.findIndex((f) => f.id === id);
        if (index >= 0) {
            const newFilters = [...filters];
            newFilters.splice(index, 1);
            setFilters(newFilters);
        }
    };

    const handleSearch = () => {
        onSearch(selectedColumns, filters);
    };

    const handleColumnSelection = (category, field) => {
        const selection = `${category}.${field}`;
        const index = selectedColumns.findIndex((c) => c === selection);
        const updatedColumns = [...selectedColumns];
        if (index >= 0) {
            updatedColumns.splice(index, 1);
        } else {
            updatedColumns.push(selection);
        }
        setSelectedColumns(updatedColumns);
    };

    const isSearchDisabled = selectedColumns.length === 0;

    const resetFilters = () => {
        setFilters([]);
    };

    const resetColumns = () => {
        setSelectedColumns([]);
    };

    return (
        <div className="rounded p-3 dataGridSearchCard">
            <div className="d-flex w-100 justify-content-between align-items-center gap-3">
                <div className="w-100">
                    <div
                        className="mt-1 shadow-sm rounded py-3 px-4 mb-2"
                        style={{ background: 'hsl(0, 0%, 94.5%)' }}
                    >
                        <div className="d-flex w-100 justify-content-between">
                            <h2 className="fs-6 fw-bold">Filters</h2>
                            <ClearAllButton onClick={resetFilters} />
                        </div>
                        <FilterSelectCard
                            searchOptions={searchOptions}
                            onFieldChange={handleFieldChange}
                            onFieldRemove={handleFieldRemove}
                            filters={filters}
                        />
                    </div>
                    <div
                        className="mt-1 shadow-sm rounded py-3 px-4"
                        style={{ background: 'hsl(0, 0%, 94.5%)' }}
                    >
                        <div className="d-flex w-100 justify-content-between">
                            <h2 className="fs-6 fw-bold">Columns</h2>
                            <ClearAllButton onClick={resetColumns} />
                        </div>
                        <ColumnSelectCard
                            searchOptions={searchOptions}
                            selectedColumns={selectedColumns}
                            onSelect={handleColumnSelection}
                        />
                    </div>
                </div>
                <button
                    className="btn btn-primary btn-lg"
                    type="button"
                    id="data-grid-search-button"
                    onClick={handleSearch}
                    disabled={isSearchDisabled}
                >
                    <SearchIcon />
                </button>
            </div>
        </div>
    );
}

export default DataGridSearchForm;
