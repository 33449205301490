import React from 'react';
import {
    // GridToolbarContainer,
    GridToolbarExport,
} from '@mui/x-data-grid';

export function TableToolbar({ fileName }) {
    return (
        <div className="d-flex w-100 justify-content-end">
            <GridToolbarExport
                csvOptions={{
                    fileName: fileName,
                    delimiter: ',',
                    utf8WithBom: true,
                }}
            />
        </div>
    );
}
