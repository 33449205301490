import React, { useMemo } from 'react';
import DropDownSearchable from '../../dropDownSearchable';

const FoundationTypeCard = ({ project, onUpdate }) => {
    const {
        ProjectDetails,
        init: { foundation_type },
    } = project;
    const selectedFoundation = ProjectDetails?.foundation_type_id;

    const options = useMemo(
        () =>
            Object.keys(foundation_type).map((key) => ({
                label: foundation_type[key].name,
                value: foundation_type[key].id,
            })),
        [foundation_type]
    );

    const selected = options.find((opt) => opt.value === selectedFoundation);

    const handleSelect = (selected) => {
        onUpdate([
            {
                sectionName: 'ProjectDetails',
                fieldName: 'foundation_type_id',
                value: Number(selected.value),
            },
        ]);
    };

    return (
        <div>
            <h3 className="cardTitle">Foundation Type</h3>
            <div className="mt-1">
                <DropDownSearchable
                    options={options}
                    value={selected}
                    onSelect={handleSelect}
                    compact={true}
                />
            </div>
        </div>
    );
};

export default FoundationTypeCard;
