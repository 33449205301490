import React from 'react';
import { RemoveIcon } from '../../icons';
import { colors } from '../../constants';

const ClearAllButton = ({ onClick }) => (
    <button
        className="btn p-0 ps-1 d-flex align-items-center"
        style={{ background: colors.goldwood, color: 'white', fontSize: '14px' }}
        onClick={onClick}
    >
    Clear All&nbsp;
        <RemoveIcon color="white" size="16px" />
    </button>
);

export default ClearAllButton;
