import React from 'react';

const ProjectSaveButton = ({ disabled, onSave }) => (
    <button
        className="btn btn-primary w-auto rounded-pill scoutViabilityFieldLabel"
        disabled={disabled}
        onClick={onSave}
    >
    Save Changes
    </button>
);

export default ProjectSaveButton;
