import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

//import { getBaseURL} from '../../../utilities/config';

export const aroApiSlice = createApi({
    reducerPath: 'aroApi',
    baseQuery: fetchBaseQuery({
    //baseUrl: getBaseURL(),
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.token;
            headers.set('Authorization', token.jwtToken);
            headers.set('Accept', 'application/json');
            return headers;
        },
        timeout: 25000,
    }),
    tagTypes: ['ScoutProperty', 'ScoutProject'],
    // endpoints injected in specific api slices
    endpoints: () => ({}),
});
