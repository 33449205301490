// components/Login.js
import React from 'react';
import { useEffect } from 'react';
import { Authenticator, useAuthenticator, View } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import './login.css';

import { useLocation, useNavigate } from 'react-router-dom';

export function Login() {
    const { route } = useAuthenticator((context) => [context.route]);
    const location = useLocation();
    const navigate = useNavigate();
    let from = location.state?.from?.pathname || '/';
    let queryParams = location.state?.from?.search || '';

    useEffect(() => {
        if (route === 'authenticated') {
            navigate(`${from}${queryParams}`, { replace: true });
        }
    }, [route, navigate, from]);

    return (
        <View className="auth-wrapper">
            <Authenticator hideSignUp={true}></Authenticator>
        </View>
    );
}
