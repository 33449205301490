import React, { useMemo, useState } from 'react';

import ScoutDashboard from '../scoutDashboard';
import ScoutViability from '../scoutViability';
import ScoutComparables from '../scoutComparables';
import ScoutBuyBox from '../scoutBuyBox';
import ScoutProject from '../scoutProject';
import ScoutTabNavigator from './scoutTabNavigator';
import { useAppSelector } from '../../store/hooks';
import { selectUserProfile } from '../../store/slices/authSlice';
import { UserTypes } from '../../constants';

export const ScoutTabNames = {
    Dashboard: 'dashboard',
    Comparables: 'comparables',
    Viability: 'viability',
    BuyBox: 'buyBox',
    Project: 'project',
};

function ScoutTabs({ property, onScoutPropertyUpdate }) {
    const userProfile = useAppSelector(selectUserProfile);
    const [selectedTab, setSelectedTab] = useState(ScoutTabNames.Dashboard);

    const selectTab = (tab) => setSelectedTab(tab);

    const tabs = useMemo(() => {
        const { Dashboard, Comparables, Viability, BuyBox } = ScoutTabNames;
        if (userProfile === UserTypes.aro) {
            if (property.propertyDetails?.Projects?.length >= 1) {
                return ScoutTabNames;
            }
            return { Dashboard, Comparables, Viability, BuyBox };
        }
        return { Dashboard };
    }, [userProfile, property]);

    const getTabContentsComponent = () => {
        switch (selectedTab) {
        case ScoutTabNames.Dashboard:
            return (
                <ScoutDashboard
                    property={property}
                    onScoutPropertyUpdate={onScoutPropertyUpdate}
                />
            );
        case ScoutTabNames.Comparables:
            return (
                <ScoutComparables
                    property={property}
                    onScoutPropertyUpdate={onScoutPropertyUpdate}
                />
            );
        case ScoutTabNames.Viability:
            return (
                <ScoutViability
                    property={property}
                    onScoutPropertyUpdate={onScoutPropertyUpdate}
                />
            );
        case ScoutTabNames.BuyBox:
            return (
                <ScoutBuyBox
                    property={property}
                    onScoutPropertyUpdate={onScoutPropertyUpdate}
                />
            );
        case ScoutTabNames.Project:
            return <ScoutProject property={property?.propertyDetails} />;
        }
        return null;
    };

    return (
        <>
            <div className="col-12">
                <ScoutTabNavigator
                    selected={selectedTab}
                    onSelect={selectTab}
                    tabs={tabs}
                />
            </div>
            <div className="mt-2">{getTabContentsComponent()}</div>
        </>
    );
}

export default ScoutTabs;
