import React, { useEffect, useState } from 'react';
import {
    useNavigate,
    useSearchParams,
    createSearchParams,
} from 'react-router-dom';
import Loader from '../loader/loader';
import DataGridSearchForm from '../datagridSearchForm';
import DataGridTable from './datagridTable';

import { convertKeyToLabel } from '../../utilities/stringHelpers';
import { dataGridFetchData } from '../../services/datagrid';
import {
    dataGridGetOptionFromSearchParams,
    dataGridGenerateQLString,
    dataGridBuildFiltersFromQueryString,
} from '../../utilities/datagrid';

function DataGrid({ searchOptions }) {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [loading, setLoading] = useState(false);
    const [columns, setColumns] = useState(() => {
        const cols = dataGridGetOptionFromSearchParams(searchParams, 'columns');
        return cols || [];
    });
    const [searchFilters, setSearchFilters] = useState(() => {
        const queryStringFilters = dataGridGetOptionFromSearchParams(
            searchParams,
            'filters'
        );
        return dataGridBuildFiltersFromQueryString(
            queryStringFilters,
            searchOptions
        );
    });

    const [searchResponse, setSearchResponse] = useState({});
    const [tableData, setTableData] = useState([]);
    const [tableColumns, setTableColumns] = useState([]);

    useEffect(() => {
        if (columns.length) {
            doSearch(columns, searchFilters);
        }
    }, []);

    useEffect(() => {
        if (!searchResponse.error && searchResponse.data) {
            handleSearchResponse(searchResponse?.metadata, searchResponse.data);
        }
    }, [searchResponse]);

    const handleSearchResponse = (metadata, data) => {
        const cols = metadata?.schema?.filter((s) => s.name != 'schema');
        const tblColumns = [];

        for (let col of columns) {
            const [table, field] = col.split('.');
            if (table && field) {
                const def = {
                    field: `${table}.${field}`,
                    headerName: convertKeyToLabel(`${table}_${field}`),
                    type: searchOptions[table][field]?.type || 'text',
                };
                tblColumns.push(def);
            }
        }

        tblColumns.push({ field: 'id' });
        setTableColumns(tblColumns);
        const rowData = data.map((r, index) => {
            const row = {};
            row.id = index;
            for (let i = 0; i < r.length; ++i) {
                let c = r[i];
                if (cols[i].type === 'INT64') {
                    c = Number(r[i]);
                }
                row[cols[i].name] = c;
            }
            return row;
        });
        setTableData(rowData);
    };

    const doSearch = async (selectedColumns, selectedFilters) => {
        setLoading(true);
        setSearchResponse({});
        const payload = {
            columns: selectedColumns,
            filters: dataGridGenerateQLString(selectedFilters, searchOptions),
        };
        const resp = await dataGridFetchData(payload);
        setSearchResponse(resp);
        setLoading(false);
        return payload;
    };

    const handleSearch = async (selectedColumns, selectedFilters) => {
        const payload = await doSearch(selectedColumns, selectedFilters);
        navigate(
            {
                pathname: '/datagrid',
                search: createSearchParams({
                    query: encodeURI(JSON.stringify(payload)),
                }).toString(),
            },
            {
                replace: true,
            }
        );
        setSearchFilters(selectedFilters);
        setColumns(selectedColumns);
    };

    const renderTableSection = () => {
        if (loading) {
            return <Loader />;
        } else if (searchResponse.error) {
            return <p>{searchResponse.error}</p>;
        } else if (tableColumns.length) {
            if (tableData.length) {
                return <DataGridTable columns={tableColumns} rows={tableData} />;
            } else {
                return <p>No results found.</p>;
            }
        }
        return null;
    };

    return (
        <div className="px-4 py-1 d-flex flex-column gap-4">
            <DataGridSearchForm
                onSearch={handleSearch}
                searchOptions={searchOptions}
                initialFilterValues={searchFilters}
                initialColumnSelections={columns}
            />
            <div>{renderTableSection()}</div>
        </div>
    );
}

export default DataGrid;
