import { aroApiSlice } from './apiSlice';
import { getBaseURL } from '../../../utilities/config';

// To Do: use baseURL and remove full url from query
export const projectApiSlice = aroApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getProject: builder.query({
            query: (projectId) => `${getBaseURL()}/project?project-id=${projectId}`,
            providesTags: ['ScoutProject'],
        }),
        updateProject: builder.mutation({
            query: ({ projectId, projectUpdates }) => {
                return {
                    url: `${getBaseURL()}/project?project-id=${projectId}`,
                    method: 'PUT',
                    body: {
                        ...projectUpdates,
                    },
                };
            },
            invalidatesTags: ['ScoutProject'],
        }),
    }),
});

export const { useLazyGetProjectQuery, useUpdateProjectMutation } =
  projectApiSlice;
