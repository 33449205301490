import React from 'react';
import { convertKeyToLabel } from '../../utilities/stringHelpers';
import { DownCaretIcon, VisibilityOffIcon } from '../../icons';

const TableFieldsSelect = ({
    title,
    fieldOptions,
    selectedFields,
    onCheck,
    onHide,
}) => {
    const handleHide = () => onHide(title);

    return (
        <div className="d-flex tableFieldSelect">
            <div className={'dropdown dropDownLight'}>
                <button
                    type="button"
                    className="btn-outline border-0 shadow-sm dropDownButton rounded-start rounded-1 pt-1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                >
                    <div className="buttonLabel">
                        {convertKeyToLabel(title)}
                        <DownCaretIcon />
                    </div>
                </button>

                <div
                    className="dropdown-menu dropDownSelectMenu"
                    aria-labelledby="dropdownMenuLink"
                >
                    {Object.keys(fieldOptions)
                        .sort((a, b) => (a > b ? 1 : -1))
                        .map((option) => {
                            const key = `${title}.${option}`;
                            return (
                                <div className="form-check aro-checkbox" key={`ColSel-${key}`}>
                                    <div className="px-4 d-flex gap-2 align-items-center">
                                        <input
                                            className="form-check-input mt-0"
                                            type="checkbox"
                                            value={option}
                                            id={`${title}-${option}`}
                                            onChange={() => onCheck(title, option)}
                                            checked={!!selectedFields.find((c) => c === key)}
                                        />
                                        <label
                                            className="form-check-label text-start optionLabel"
                                            htmlFor={`${title}-${option}`}
                                        >
                                            {convertKeyToLabel(option)}
                                        </label>
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
            <button
                className="btn-outline border-0 shadow-sm rounded-end py-0 px-1 visibilityButton"
                onClick={handleHide}
            >
                <VisibilityOffIcon size="14px" />
            </button>
        </div>
    );
};

export default TableFieldsSelect;
