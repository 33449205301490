import React, { useMemo } from 'react';
import DropDownSearchable from '../../dropDownSearchable';

const DevelopmentManagerCard = ({ project, onUpdate }) => {
    const {
        ProjectDetails,
        init: { person },
    } = project;
    const selectedPersonId = ProjectDetails?.development_manager_id || null;

    const options = useMemo(() => {
        const people = [];
        for (let key in person) {
            if (person[key].family_name) {
                people.push({
                    label: `${person[key].given_name} ${person[key].family_name}`,
                    value: Number(key),
                });
            }
        }
        people.sort((a, b) => (a.label > b.label ? 1 : -1));
        return [{ label: 'not assigned', value: null }, ...people];
    }, [person]);

    const selected = options.find((opt) => opt.value === selectedPersonId);

    const handleSelect = (selection) => {
        onUpdate([
            {
                sectionName: 'ProjectDetails',
                fieldName: 'development_manager_id',
                value: selection.value,
            },
        ]);
    };

    return (
        <div>
            <h3 className="cardTitle">Development Manager</h3>
            <div className="mt-1">
                <DropDownSearchable
                    options={options}
                    sortOptions={false}
                    value={selected}
                    onSelect={handleSelect}
                    compact={true}
                />
            </div>
        </div>
    );
};

export default DevelopmentManagerCard;
