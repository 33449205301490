import React from 'react';

import './scoutHeader.css';
import { colors } from '../../constants';
import { AppleIcon, GoogleIcon } from '../../icons';

function ScoutHeader({ propertyDetails }) {
    const { City, PropertyGeometry, PropertyAddress, Street } = propertyDetails;

    const streetAddress = `${PropertyAddress.house_number} ${Street.direction} ${Street.name} ${Street.suffix} ${Street.post_direction}`;
    const displayStreetAddress = streetAddress
        .toLowerCase()
        .replace(/\s+/g, ' ')
        .trim();
    const cityState = `${City.name.toLowerCase()} ${City.state}`;

    return (
        <div className="d-flex w-100 flex-wrap align-items-center justify-content-between">
            <h1 className="fs-1 lh-1 text-capitalize mb-1">
                {displayStreetAddress}, <span className="fst-italic">{cityState}</span>
            </h1>
            <div className="d-flex mt-2 mt-md-0">
                <div>
                    <span className="googleMap">
                        <a
                            target="_blank"
                            href={`http://maps.google.com/maps?z=12&t=k&q=${PropertyGeometry.latitude},${PropertyGeometry.longitude}`}
                            rel="noreferrer"
                        >
                            <GoogleIcon />
                        </a>
                    </span>
                    <span className="appleMap">
                        <a
                            target="_blank"
                            href={`http://maps.apple.com/?q=${PropertyGeometry.latitude},${PropertyGeometry.longitude}&t=k`}
                            rel="noreferrer"
                        >
                            <AppleIcon />
                        </a>
                    </span>
                    <span className="zillowIcon">
                        <a
                            target="_blank"
                            href={`https://www.zillow.com/homes/${PropertyAddress.house_number}-${Street.name}-${Street.suffix}`}
                            rel="noreferrer"
                        >
                            <svg
                                style={{ color: colors.charcoal }}
                                role="img"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    d="M12.006 0L1.086 8.627v3.868c3.386-2.013 11.219-5.13 14.763-6.015.11-.024.16.005.227.078.372.427 1.586 1.899 1.916 2.301a.128.128 0 0 1-.03.195 43.607 43.607 0 0 0-6.67 6.527c-.03.037-.006.043.012.03 2.642-1.134 8.828-2.94 11.622-3.452V8.627zm-.48 11.177c-2.136.708-8.195 3.307-10.452 4.576V24h21.852v-7.936c-2.99.506-11.902 3.16-15.959 5.246a.183.183 0 0 1-.23-.036l-2.044-2.429c-.055-.061-.062-.098.011-.208 1.574-2.3 4.789-5.899 6.833-7.418.042-.03.031-.06-.012-.042Z"
                                    fill="#3d3935"
                                ></path>
                            </svg>
                        </a>
                    </span>
                </div>
            </div>
        </div>
    );
}

export default ScoutHeader;
