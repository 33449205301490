import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import UserProfileMenu from '../userProfileMenu';
import { AroLogoHouse, DataGridIcon, KanbanIcon, MapIcon } from '../../icons';
import './navigation.css';

const showToggleMaxWidth = 768;

function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
}

const Navigation = ({ navOpen }) => {
    const { pathname } = useLocation();
    const [windowSize, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const isVisible = useMemo(
        () => (windowSize.innerWidth < showToggleMaxWidth ? navOpen : true),
        [windowSize, navOpen]
    );

    const visibilityClass = isVisible ? 'd-block' : 'inActive';

    const getBackgroundClass = (path) => (path === pathname ? 'highlight' : '');

    return (
        <div className={`verticalNav bg-white ${visibilityClass}`} id="sidebar">
            <div className="py-4 d-flex flex-column align-items-center">
                <div>
                    <AroLogoHouse size={84} />
                    <ul className="mt-3 nav flex-column bg-white gap-2">
                        <li
                            className={`nav-item text-center rounded mx-3 ${getBackgroundClass(
                                '/'
                            )}`}
                        >
                            <Link to={{ pathname: '/' }} title="Map">
                                <MapIcon />
                            </Link>
                        </li>
                        <li
                            className={`nav-item text-center rounded mx-3 sidebarLink ${getBackgroundClass(
                                '/kanban'
                            )}`}
                        >
                            <Link to={{ pathname: '/kanban' }} title="Kanban">
                                <KanbanIcon />
                            </Link>
                        </li>
                        <li
                            className={`nav-item text-center rounded mx-3 sidebarLink ${getBackgroundClass(
                                '/datagrid'
                            )}`}
                        >
                            <Link to={{ pathname: '/datagrid' }} title="Data Grid">
                                <DataGridIcon />
                            </Link>
                        </li>
                    </ul>
                </div>
                {isVisible && (
                    <div className="d-flex flex-column gap-1 endOptionsContainer">
                        <UserProfileMenu />
                        <div
                            className={`nav-item text-center rounded mx-3 sidebarLink ${getBackgroundClass(
                                '/terms'
                            )}`}
                        >
                            <Link
                                to={{ pathname: '/terms' }}
                                title="Terms of Use"
                                target="_blank"
                                className="termsOfUseLink"
                            >
                                <span>Terms of Use</span>
                            </Link>
                        </div>
                        
                    </div>
                )}
            </div>
        </div>
    );
};

export default Navigation;
