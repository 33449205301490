import React from 'react';

const DataGridIcon = () => (
    <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        width="48px"
        height="48px"
        viewBox="0 0 40 40"
        enableBackground="new 0 0 40 40"
        xmlSpace="preserve"
    >
        <g display="none">
            <path
                display="inline"
                d="M20.01,36.5c-0.277,0-0.537-0.137-0.693-0.368L9.468,21.529c-1.171-1.861-1.79-3.993-1.79-6.162
            C7.678,8.824,13.206,3.5,20,3.5c6.795,0,12.322,5.324,12.322,11.867c0,2.164-0.612,4.284-1.77,6.129l-9.851,14.636
            C20.547,36.363,20.287,36.5,20.01,36.5L20.01,36.5z M20,5.17c-5.873,0-10.652,4.574-10.652,10.197c0,1.854,0.525,3.669,1.52,5.251
            l9.14,13.55l9.146-13.581c0.981-1.566,1.499-3.371,1.499-5.22C30.652,9.744,25.873,5.17,20,5.17L20,5.17z"
            />
            <path
                display="inline"
                d="M20,20.857c-3.159,0-5.728-2.482-5.728-5.535c0-3.051,2.569-5.534,5.728-5.534s5.729,2.483,5.729,5.534
            C25.729,18.375,23.158,20.857,20,20.857L20,20.857z M20,11.458c-2.237,0-4.057,1.734-4.057,3.864c0,2.13,1.82,3.865,4.057,3.865
            s4.058-1.734,4.058-3.865C24.058,13.192,22.236,11.458,20,11.458L20,11.458z"
            />
        </g>
        <path
            display="none"
            d="M36.705,34.289L26.059,23.615c3.918-4.69,3.688-11.708-0.707-16.114C23.1,5.243,20.104,4,16.919,4
        S10.74,5.243,8.488,7.501c-4.647,4.66-4.647,12.241,0,16.899c2.253,2.257,5.248,3.5,8.431,3.5c2.866,0,5.573-1.015,7.728-2.86
        l10.639,10.665C35.479,35.902,35.738,36,35.994,36s0.514-0.098,0.709-0.293C37.096,35.316,37.097,34.68,36.705,34.289z
         M9.766,23.126c-3.945-3.958-3.945-10.395,0-14.351c1.912-1.914,4.452-2.97,7.153-2.97s5.243,1.056,7.153,2.97
        c3.946,3.956,3.946,10.394,0,14.351c-1.91,1.914-4.452,2.969-7.153,2.969S11.678,25.04,9.766,23.126z"
        />
        <path
            display="none"
            d="M25.38,34.848c-0.066,0-0.136-0.009-0.206-0.024l-10.498-2.561l-10.61,2.561
        c-0.069,0.016-0.139,0.024-0.205,0.024c-0.191,0-0.38-0.064-0.532-0.184C3.12,34.5,3,34.252,3,33.986V8.635
        c0-0.397,0.27-0.741,0.657-0.836l10.76-2.623l0.407,0.003l10.504,2.558l10.607-2.561c0.065-0.016,0.135-0.023,0.203-0.023
        c0.195,0,0.38,0.063,0.533,0.183C36.881,5.499,37,5.746,37,6.012v25.352c0,0.397-0.27,0.741-0.656,0.837l-10.759,2.622
        C25.516,34.839,25.446,34.848,25.38,34.848L25.38,34.848z M15.481,30.688l9.039,2.203V9.311l-9.039-2.203V30.688z M26.24,9.311
        v23.58l9.039-2.202V7.108L26.24,9.311z M4.721,9.311v23.58l9.039-2.202V7.108L4.721,9.311z"
        />
        <g display="none">
            <path
                display="inline"
                d="M9.708,35C7.112,35,5,32.893,5,30.303c0-2.592,2.112-4.699,4.708-4.699c2.595,0,4.707,2.107,4.707,4.699
            C14.415,32.893,12.303,35,9.708,35L9.708,35z M9.708,27.445c-1.578,0-2.863,1.281-2.863,2.857c0,1.574,1.285,2.855,2.863,2.855
            c1.578,0,2.861-1.281,2.861-2.855C12.568,28.727,11.285,27.445,9.708,27.445L9.708,27.445z"
            />
            <path
                display="inline"
                d="M24.574,35c-0.621,0-1.125-0.505-1.125-1.126c0-9.552-7.771-17.324-17.323-17.324
            C5.505,16.55,5,16.045,5,15.425s0.505-1.126,1.126-1.126c10.792,0,19.573,8.781,19.573,19.575C25.699,34.495,25.193,35,24.574,35
            L24.574,35z"
            />
            <path
                display="inline"
                d="M33.916,35c-0.597,0-1.082-0.486-1.082-1.084c0-14.75-12-26.751-26.751-26.751
            C5.486,7.165,5,6.68,5,6.083C5,5.486,5.486,5,6.083,5C22.027,5,35,17.972,35,33.916C35,34.514,34.514,35,33.916,35L33.916,35z"
            />
        </g>
        <g display="none">
            <path
                display="inline"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20,29.616c-6.911,0-13.412-3.681-17-9.615
            c3.588-5.935,10.089-9.617,17-9.617c6.91,0,13.412,3.682,17,9.617C33.412,25.936,26.91,29.616,20,29.616L20,29.616z M19.998,12.254
            c-5.817,0-11.309,2.848-14.687,7.618l-0.088,0.125l0.088,0.124c3.378,4.77,8.87,7.618,14.687,7.618
            c5.82,0,11.311-2.849,14.687-7.618l0.089-0.124l-0.089-0.125C31.309,15.103,25.818,12.254,19.998,12.254L19.998,12.254z"
            />
            <path
                display="inline"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.987,13.521c-3.574,0-6.478,2.9-6.478,6.479
            c0,3.579,2.904,6.478,6.478,6.478c3.581,0,6.478-2.898,6.478-6.478C26.465,16.42,23.568,13.521,19.987,13.521z M19.987,24.857
            c-2.68,0-4.858-2.175-4.858-4.858s2.178-4.859,4.858-4.859c2.686,0,4.858,2.175,4.858,4.859S22.673,24.857,19.987,24.857z"
            />
        </g>
        <path
            display="none"
            d="M7.336,35.5c-0.399,0-0.725-0.325-0.725-0.726v-7.599c0-0.4,0.326-0.725,0.725-0.725h3.181v-6.336
        c0-0.342,0.276-0.619,0.619-0.619h8.247v-5.949h-3.181c-0.398,0-0.725-0.325-0.725-0.726V5.223c0-0.399,0.326-0.723,0.725-0.723
        h7.598c0.398,0,0.725,0.324,0.725,0.723v7.599c0,0.401-0.326,0.726-0.725,0.726H20.62v5.949h8.245c0.341,0,0.618,0.277,0.618,0.619
        v6.336h3.182c0.397,0,0.724,0.324,0.724,0.725v7.599c0,0.399-0.326,0.725-0.724,0.725h-7.599c-0.4,0-0.726-0.325-0.726-0.725v-7.599
        c0-0.4,0.325-0.725,0.726-0.725h3.179v-5.717H11.754v5.717h3.18c0.399,0,0.725,0.324,0.725,0.725v7.599
        c0,0.4-0.325,0.726-0.725,0.726H7.336z M8.061,34.051h6.149V27.9H8.061V34.051z M25.79,34.051h6.149V27.9H25.79V34.051z
         M16.927,12.099h6.146V5.949h-6.146V12.099z"
        />
        <g display="none">
            <path
                display="inline"
                d="M11.018,35.01c-0.402,0-0.736-0.314-0.759-0.717L8.879,9.946H5.75c-0.419,0-0.76-0.342-0.76-0.76
            c0-0.419,0.341-0.761,0.76-0.761h3.59L9.495,8.45L9.6,8.426h5.171V5.75c0-0.419,0.34-0.76,0.76-0.76h8.939
            c0.419,0,0.76,0.341,0.76,0.76v2.676h5.174l0.175,0.028l0.118-0.028h3.554c0.419,0,0.76,0.342,0.76,0.761
            c0,0.418-0.341,0.76-0.76,0.76h-3.128l-1.36,24.346c-0.023,0.402-0.356,0.718-0.759,0.718H11.018z M11.735,33.49h16.549L29.6,9.946
            H10.4L11.735,33.49z M16.29,8.426h7.42V6.51h-7.42V8.426z"
            />
            <path
                display="inline"
                d="M14.302,12.201c-0.249,0.012-0.435,0.102-0.57,0.252c-0.135,0.152-0.203,0.348-0.192,0.551l0.998,17.608
            c0.024,0.401,0.357,0.716,0.759,0.716v0.19l0.045-0.19c0.417-0.024,0.737-0.385,0.715-0.804l-0.999-17.606
            C15.034,12.516,14.702,12.201,14.302,12.201L14.302,12.201z"
            />
            <path
                display="inline"
                d="M25.709,12.202c-0.445,0-0.776,0.314-0.8,0.716l-1,17.606c-0.022,0.419,0.298,0.779,0.717,0.804
            l0.044,0.19v-0.19c0.402,0,0.735-0.314,0.757-0.716l1.001-17.608c0.011-0.203-0.058-0.398-0.192-0.551
            c-0.136-0.15-0.321-0.24-0.523-0.251C25.711,12.202,25.71,12.202,25.709,12.202L25.709,12.202z"
            />
            <path
                display="inline"
                d="M20,12.201c-0.419,0-0.76,0.341-0.76,0.76v17.607c0,0.419,0.341,0.76,0.76,0.76s0.76-0.341,0.76-0.76
            V12.961C20.76,12.542,20.419,12.201,20,12.201L20,12.201z"
            />
        </g>
        <g display="none">
            <path
                display="inline"
                d="M8.236,15.765C5.901,15.765,4,17.665,4,20c0,2.336,1.901,4.234,4.236,4.234
            c2.336,0,4.235-1.898,4.235-4.234C12.472,17.665,10.572,15.765,8.236,15.765z M8.236,22.824c-1.557,0-2.823-1.268-2.823-2.824
            c0-1.557,1.267-2.824,2.823-2.824c1.558,0,2.822,1.267,2.822,2.824C11.059,21.557,9.794,22.824,8.236,22.824z"
            />
            <path
                display="inline"
                d="M20,15.765c-2.334,0-4.236,1.899-4.236,4.235c0,2.336,1.902,4.234,4.236,4.234
            c2.337,0,4.236-1.898,4.236-4.234C24.236,17.665,22.337,15.765,20,15.765z M20,22.824c-1.557,0-2.822-1.268-2.822-2.824
            c0-1.557,1.266-2.824,2.822-2.824c1.559,0,2.822,1.267,2.822,2.824C22.822,21.557,21.559,22.824,20,22.824z"
            />
            <path
                display="inline"
                d="M31.764,15.765c-2.334,0-4.235,1.899-4.235,4.235c0,2.336,1.901,4.234,4.235,4.234
            C34.101,24.234,36,22.336,36,20C36,17.665,34.101,15.765,31.764,15.765z M31.764,22.824c-1.556,0-2.822-1.268-2.822-2.824
            c0-1.557,1.267-2.824,2.822-2.824c1.559,0,2.823,1.267,2.823,2.824C34.587,21.557,33.322,22.824,31.764,22.824z"
            />
        </g>
        <path
            display="none"
            d="M33.125,19.125H8.98L20.841,7.5c0.345-0.338,0.351-0.892,0.013-1.237s-0.892-0.35-1.237-0.012L6.262,19.339
        C6.095,19.503,6,19.729,6,19.965c0,0.005,0,0.011,0,0.017C6,19.989,6,19.994,6,20c0,0.319,0.171,0.598,0.427,0.751L19.69,33.75
        c0.17,0.167,0.392,0.25,0.613,0.25c0.227,0,0.453-0.088,0.625-0.262c0.338-0.346,0.332-0.9-0.013-1.238L9.055,20.875h24.07
        C33.607,20.875,34,20.483,34,20S33.607,19.125,33.125,19.125z"
        />
        <path
            display="none"
            d="M34,20c0-0.32-0.172-0.599-0.428-0.751L20.31,6.25c-0.345-0.338-0.899-0.333-1.237,0.012
        S18.738,7.161,19.084,7.5l11.861,11.625H6.875C6.393,19.125,6,19.517,6,20s0.393,0.875,0.875,0.875H31.02L19.158,32.5
        c-0.344,0.339-0.35,0.893-0.012,1.238C19.318,33.912,19.545,34,19.771,34c0.221,0,0.442-0.083,0.612-0.25l13.353-13.089
        C33.904,20.497,34,20.271,34,20.035c0-0.005-0.001-0.011-0.001-0.017C33.999,20.011,34,20.006,34,20z"
        />
        <path
            display="none"
            d="M33.75,19.616L20.66,6.262C20.496,6.095,20.271,6,20.037,6c-0.007,0-0.014,0.001-0.02,0.001S20.006,6,20,6
        c-0.32,0-0.598,0.172-0.751,0.428L6.25,19.69c-0.338,0.345-0.332,0.899,0.013,1.237c0.171,0.167,0.391,0.25,0.612,0.25
        c0.227-0.001,0.454-0.088,0.625-0.263l11.625-11.86v24.07C19.125,33.607,19.518,34,20,34c0.483,0,0.875-0.393,0.875-0.875V8.981
        L32.5,20.841c0.338,0.345,0.893,0.35,1.237,0.013C34.082,20.515,34.088,19.96,33.75,19.616z"
        />
        <path
            display="none"
            d="M33.737,19.071c-0.345-0.337-0.899-0.332-1.237,0.014l-11.625,11.86V6.875C20.875,6.392,20.483,6,20,6
        c-0.482,0-0.875,0.392-0.875,0.875V31.02L7.5,19.159c-0.338-0.345-0.892-0.351-1.237-0.013c-0.345,0.338-0.351,0.892-0.013,1.237
        l13.09,13.354C19.504,33.905,19.729,34,19.965,34c0.005,0,0.012-0.001,0.018-0.001C19.989,33.999,19.994,34,20,34
        c0.32,0,0.599-0.172,0.752-0.428L33.75,20.31C34.088,19.965,34.082,19.41,33.737,19.071z"
        />
        <path
            display="none"
            d="M26.714,34c-0.222,0-0.442-0.083-0.612-0.251l-13.429-13.16c-0.167-0.164-0.262-0.39-0.262-0.624
        c0-0.236,0.095-0.462,0.262-0.626L26.027,6.251c0.344-0.339,0.898-0.334,1.237,0.011c0.338,0.346,0.331,0.899-0.013,1.237
        L14.536,19.965l12.79,12.536c0.345,0.337,0.351,0.892,0.013,1.237C27.168,33.912,26.939,34,26.714,34z"
        />
        <path
            display="none"
            d="M13.36,34c-0.225,0-0.453-0.088-0.624-0.262c-0.339-0.346-0.333-0.899,0.01-1.237l12.717-12.466
        L12.673,7.499c-0.345-0.339-0.35-0.893-0.013-1.237c0.34-0.344,0.894-0.35,1.238-0.012l13.429,13.161
        c0.167,0.164,0.262,0.39,0.262,0.624c0,0.236-0.094,0.461-0.262,0.625L13.974,33.75C13.803,33.917,13.581,34,13.36,34z"
        />
        <path
            display="none"
            d="M5.938,28.131c-0.236,0-0.474-0.089-0.657-0.269c-0.368-0.362-0.375-0.956-0.012-1.325L19.37,12.149
        c0.176-0.179,0.417-0.28,0.669-0.28s0.493,0.101,0.669,0.28l14.023,14.308c0.363,0.369,0.356,0.963-0.012,1.326
        c-0.37,0.361-0.965,0.356-1.326-0.014L20.039,14.146L6.608,27.85C6.423,28.037,6.182,28.13,5.938,28.131z"
        />
        <path
            display="none"
            d="M19.962,28.131c-0.253,0-0.493-0.102-0.67-0.281L5.27,13.542c-0.364-0.37-0.357-0.963,0.011-1.326
        c0.372-0.362,0.965-0.356,1.326,0.013l13.354,13.625l13.433-13.704c0.361-0.37,0.954-0.376,1.326-0.015
        c0.368,0.363,0.375,0.957,0.013,1.327L20.632,27.85C20.454,28.029,20.214,28.131,19.962,28.131z"
        />
        <g>
            <path
                d="M6.72,34C6.323,34,6,33.678,6,33.281v-5.811c0-0.396,0.323-0.721,0.72-0.721h5.81c0.397,0,0.72,0.324,0.72,0.721v5.811
            c0,0.396-0.323,0.719-0.72,0.719H6.72z M7.439,32.561h4.371v-4.371H7.439V32.561z"
            />
            <path
                d="M17.095,34c-0.396,0-0.72-0.322-0.72-0.719v-5.811c0-0.396,0.323-0.721,0.72-0.721h5.811c0.396,0,0.72,0.324,0.72,0.721
            v5.811c0,0.396-0.323,0.719-0.72,0.719H17.095z M17.815,32.561h4.371v-4.371h-4.371V32.561z"
            />
            <path
                d="M27.471,34c-0.397,0-0.721-0.322-0.721-0.719v-5.811c0-0.396,0.323-0.721,0.721-0.721h5.81c0.396,0,0.72,0.324,0.72,0.721
            v5.811C34,33.678,33.677,34,33.28,34H27.471z M28.189,32.561h4.371v-4.371h-4.371V32.561z"
            />
            <path
                d="M6.72,23.625c-0.396,0-0.72-0.322-0.72-0.719v-5.811c0-0.397,0.323-0.72,0.72-0.72h5.81c0.397,0,0.72,0.323,0.72,0.72
            v5.811c0,0.396-0.323,0.719-0.72,0.719H6.72z M7.439,22.186h4.371v-4.371H7.439V22.186z"
            />
            <path
                d="M17.095,23.625c-0.396,0-0.72-0.322-0.72-0.719v-5.811c0-0.397,0.323-0.72,0.72-0.72h5.811c0.396,0,0.72,0.323,0.72,0.72
            v5.811c0,0.396-0.323,0.719-0.72,0.719H17.095z M17.815,22.186h4.371v-4.371h-4.371V22.186z"
            />
            <path
                d="M27.471,23.625c-0.397,0-0.721-0.322-0.721-0.719v-5.811c0-0.397,0.323-0.72,0.721-0.72h5.81c0.396,0,0.72,0.323,0.72,0.72
            v5.811c0,0.396-0.323,0.719-0.72,0.719H27.471z M28.189,22.186h4.371v-4.371h-4.371V22.186z"
            />
            <path
                d="M6.72,13.25C6.323,13.25,6,12.927,6,12.531v-5.81C6,6.324,6.323,6,6.72,6h5.81c0.397,0,0.72,0.323,0.72,0.72v5.81
            c0,0.396-0.323,0.719-0.72,0.719H6.72z M7.439,11.811h4.371V7.439H7.439V11.811z"
            />
            <path
                d="M17.095,13.25c-0.396,0-0.72-0.322-0.72-0.719v-5.81c0-0.397,0.323-0.72,0.72-0.72h5.811c0.396,0,0.72,0.323,0.72,0.72
            v5.81c0,0.396-0.323,0.719-0.72,0.719H17.095z M17.815,11.811h4.371V7.439h-4.371V11.811z"
            />
            <path
                d="M27.471,13.25c-0.397,0-0.721-0.322-0.721-0.719v-5.81c0-0.397,0.323-0.72,0.721-0.72h5.81C33.677,6,34,6.324,34,6.721
            v5.81c0,0.396-0.323,0.719-0.72,0.719H27.471z M28.189,11.811h4.371V7.439h-4.371V11.811z"
            />
        </g>
    </svg>
);

export default DataGridIcon;
