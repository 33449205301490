import React from 'react';
import DropDownSelect from '../dropDownSelect';
import { getKeyByValue } from '../../utilities/objectHelpers';

const UnitOptions = {
    days: 'd',
    weeks: 'w',
};

const RelativeDateInput = ({ value, onChange }) => {
    const units = value ? getKeyByValue(UnitOptions, value?.units) || '' : '';
    const inputValue = value ? value?.nUnits || '' : '';

    const handleInputChange = (e) => onChange(UnitOptions[units], e.target.value);
    const handleUnitsChange = (newUnits) =>
        onChange(UnitOptions[newUnits], inputValue);

    return (
        <div className="px-1 d-flex w-100 gap-2 align-items-center">
            <div className="d-flex gap-1">
                <input
                    type="number"
                    autoFocus
                    value={inputValue}
                    onChange={handleInputChange}
                    style={{
                        textAlign: 'right',
                        width: '90px',
                    }}
                />
                <DropDownSelect
                    options={Object.keys(UnitOptions)}
                    selected={units}
                    onSelect={handleUnitsChange}
                    label="Select"
                    buttonStyling={{ height: '35px' }}
                />
            </div>
        </div>
    );
};

export default RelativeDateInput;
