import React, { useMemo, useState } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { TableToolbar } from './tableToolbar';
import { formatDateMMDDYY } from '../../utilities/dateHelpers';

function dateFormatter(params) {
    return params ? formatDateMMDDYY(new Date(params)) : '';
}
function currencyFormatter(value) {
    if (!value) {
        return '';
    }
    let formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2,
    });
    return formatter.format(value);
}

function DataGridTable({ rows, columns }) {
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({
        id: false,
    });

    const tableColumns = useMemo(() => {
        return columns.map((col) => {
            const def = { ...col };
            switch (col.type) {
            case 'date':
                def.valueFormatter = dateFormatter;
                def.width = 125;
                break;
            case 'currency':
                def.align = 'right';
                def.valueFormatter = currencyFormatter;
                def.width = 150;
                break;
            default:
                def.flex = 1;
                break;
            }
            return def;
        });
    }, [columns]);

    return (
        <div className="w-100" style={{ height: '100vh' }}>
            <DataGridPro
                rows={rows}
                columns={tableColumns}
                autoPageSize={true}
                pagination={true}
                slots={{
                    toolbar: TableToolbar,
                }}
                slotProps={{
                    pagination: {
                        showFirstButton: true,
                        showLastButton: true,
                    },
                    toolbar: { fileName: 'datagrid' },
                }}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                    setColumnVisibilityModel(newModel)
                }
                rowHeight={32}
                sx={{
                    '& .MuiDataGrid-columnHeaderTitle': {
                        fontWeight: '700',
                        whiteSpace: 'normal',
                        lineHeight: 'normal',
                    },
                    '& .MuiDataGrid-virtualScrollerContent': {
                        background: '#fcfcfc', //'#f8f8f6',
                    },
                    '& .MuiDataGrid-columnHeader': {
                        paddingTop: '10px',
                        paddingBottom: '8px',
                        // Forced to use important since overriding inline styles
                        height: 'unset !important',
                    },
                    '& .MuiDataGrid-columnHeaders': {
                        // Forced to use important since overriding inline styles
                        maxHeight: '168px !important',
                    },
                }}
            />
        </div>
    );
}

export default DataGridTable;
