import { getDataGridURL } from '../utilities/config';

import { parquetMetadata, parquetRead } from 'hyparquet';

const fetchDG = async (query) => {
    try {
        console.time('dataGridFetch', query);
        const url = getDataGridURL();
        const res = await fetch(
            `${url}/v1?query=${encodeURI(JSON.stringify(query))}`
        );
        console.timeEnd('dataGridFetch');
        if (res.status === 400) {
            const info = await res.json();
            return { error: info?.error || 'V1 HTTP status 400' };
        }
        const arrayBuffer = await res.arrayBuffer();
        return { arrayBuffer };
    } catch (e) {
        return { error: 'V1 API Error' };
    }
};

export const dataGridFetchData = async (query) => {
    const resp = await fetchDG(query);
    let rtn = {};
    if (resp.error) {
        return resp;
    } else if (resp.arrayBuffer) {
        try {
            const metadata = parquetMetadata(resp.arrayBuffer);
            console.log('parquet metadata', metadata);
            const columns = metadata?.schema?.filter((c) => c.name != 'schema');
            const columnNames = columns.map((c) => c.name);
            await parquetRead({
                file: resp.arrayBuffer,
                columns: [...columnNames],
                rowStart: 0,
                rowEnd: metadata?.num_rows ? Number(metadata.num_rows) : 199,
                onComplete: (data) => (rtn = { metadata, data }),
            });
        } catch (e) {
            console.log('parquet data handling error', e);
            rtn.error = 'Parquet data handling error';
        }
    }
    return rtn;
};
