import React from 'react';

const NotesCard = ({ project, onUpdate }) => {
    const { ProjectDetails } = project;

    const handleChange = (ev) =>
        onUpdate([
            {
                sectionName: 'ProjectDetails',
                fieldName: 'notes',
                value: ev.target.value,
            },
        ]);

    return (
        <div className="d-flex flex-column h-100">
            <h3 className="cardTitle">Notes</h3>
            <textarea
                className="form-control h-100 shadow-sm pt-2 px-3 flex-grow-1"
                value={ProjectDetails?.notes || ''}
                onChange={handleChange}
            />
        </div>
    );
};

export default NotesCard;
